import agendamentoService from '@/services/agendamento'
import asoService from '@/services/aso'

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const SET_ID_FUNCIONARIO = 'SET_ID_FUNCIONARIO'
export const SET_CLINICA = 'SET_CLINICA'
export const SET_EXAME = 'SET_EXAME'
export const SET_DT_AGENDAMENTO = 'SET_DT_AGENDAMENTO'
export const SET_CLINICA_SEARCH = 'SET_CLINICA_SEARCH'
export const SET_EXAME_ONLINE = 'SET_EXAME_ONLINE'
export const SET_ID_HORARIO = 'SET_ID_HORARIO'
export const RESET_AGENDAMENTO = 'RESET_AGENDAMENTO'
export const SET_LINK_GUIA = 'SET_LINK_GUIA'

/**
 * Store do Agendamento
 *
 * Steps disponíveis:
 *  -> funcionario
 *  -> exame
 *  -> clinica
 *  -> agenda-medica
 *  -> protocolo
 */

const agendamento = {
  id_funcionario: null,
  exame: null,
  dt_agendamento: null,
  clinica: {
    id_clinica: null,
    horario_agendado: null,
  },
  id_horario: null,
}

const state = {
  activeStep: null,
  transitionDuration: null,
  exameOnline: false,
  agendamento,
  linkGuia: null,
}

const mutations = {
  [SET_EXAME_ONLINE]: (state, payload) => {
    state.exameOnline = payload
  },
  [SET_ACTIVE_STEP]: (state, { step, duration = null }) => {
    state.activeStep = step
    state.transitionDuration = duration
  },
  [SET_ID_FUNCIONARIO]: (state, payload) => {
    state.agendamento = {
      ...state.agendamento,
      id_funcionario: payload,
    }
  },
  [SET_CLINICA]: (state, { id_clinica, horario_agendado }) => {
    state.agendamento.clinica = {
      ...state.agendamento.clinica,
      id_clinica,
      horario_agendado,
    }
  },
  [SET_EXAME]: (state, payload) => {
    state.agendamento = {
      ...state.agendamento,
      exame: payload,
    }
  },
  [SET_DT_AGENDAMENTO]: (state, payload) => {
    state.agendamento = {
      ...state.agendamento,
      dt_agendamento: payload,
    }
  },
  [SET_ID_HORARIO]: (state, payload) => {
    state.agendamento = {
      ...state.agendamento,
      id_horario: payload,
    }
  },
  [SET_LINK_GUIA]: (state, payload) => {
    state.linkGuia = payload
  },
}

const actions = {
  async getInfoAgendamento({ state, dispatch }) {
    try {
      const { id_funcionario, exame } = state.agendamento
      const response = await agendamentoService.getInfoAgendamento(id_funcionario, exame)

      return response.data
    } catch (error) {
      const { status } = error.response

      if (status === 400) {
        dispatch('dashboard/showErrorOnSnackbar', error.response.data.message, { root: true })
      } else {
        dispatch('dashboard/showErrorOnSnackbar', 'Erro! Entre em contato com nosso time', { root: true })
      }
    }
  },
  async saveAso({ state, commit }, payload) {
    const { agendamento } = state

    const params = {
      agendamento,
      ...payload,
    }

    const response = await asoService.save(params, state.exameOnline)
    commit(SET_LINK_GUIA, response.data.link)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
