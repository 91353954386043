const AdminView = () => import('@/views/Admin/AdminView')
const UsuarioCreate = () => import('@/views/Admin/UsuarioCreate')
const Aso = () => import('@/views/Admin/Aso')
const Clinica = () => import('@/views/Admin/Clinica')
const Usuarios = () => import('@/views/Admin/Usuarios')
const Medico = () => import('@/views/Admin/Medico')
const Faturamento = () => import('@/views/Admin/Faturamento')
const CadastroRapido = () => import('@/views/Admin/CadastroRapido')
const AgendaMedica = () => import('@/views/Admin/AgendaMedica')
const NovosNegocios = () => import('@/views/Home/NovosNegocios')
const Exames = () => import('@/views/Admin/Exames')
const Riscos = () => import('@/views/Admin/Riscos')
const Avisos = () => import('@/views/Admin/Avisos')
const ImportarFuncionarios = () => import('@/views/Home/ImportarFuncionarios')
const ImportarUnidades = () => import('@/views/Home/ImportarUnidades')
const DetalhesFatura = () => import('@/views/Home/DetalhesFatura')
const GerenciarAtendimentos = () => import('@/views/Admin/GerenciarAtendimentos')

export default {
  path: 'admin/',
  name: 'admin',
  component: AdminView,
  children: [
    { path: 'user/create', name: 'create-new-user', component: UsuarioCreate },
    { path: 'asos', name: 'admin-asos', component: Aso },
    { path: 'clinicas', name: 'admin-clinicas', component: Clinica },
    { path: 'usuarios', name: 'admin-usuarios', component: Usuarios },
    { path: 'medicos', name: 'admin-medicos', component: Medico },
    { path: 'faturamento', name: 'admin-faturamento', component: Faturamento },
    { path: 'cadastro-rapido', name: 'cadastro-rapido', component: CadastroRapido },
    { path: 'agenda-medica', name: 'admin-agenda-medica', component: AgendaMedica },
    { path: 'novos-negocios', name: 'admin-novos-negocios', component: NovosNegocios },
    { path: 'exames', name: 'admin-exames', component: Exames },
    { path: 'riscos', name: 'admin-riscos', component: Riscos },
    { path: 'avisos', name: 'admin-avisos', component: Avisos },
    { path: 'importar-funcionarios', name: 'importar-funcionarios', component: ImportarFuncionarios },
    { path: 'importar-unidades', name: 'importar-unidades', component: ImportarUnidades },
    { path: 'informacoes-fatura/:id', name: 'informacoes-fatura', component: DetalhesFatura, props: true },
    // { path: 'faturamento/:empresa', name: 'faturamento', component: Faturamento, props: true },
    { path: 'gerenciar-atendimentos', name: 'gerenciar-atendimentos', component: GerenciarAtendimentos },
  ],
}
