import negocioService from '@/services/negocio'
import { extractOnlyNumbers } from '@/core/utils/formatter'
import { TIPOS_ASSINANTES } from '@/constants'

export const RESET_STATE = 'RESET_STATE'
export const SET_STEP = 'SET_STEP'
export const SET_ORGANIZACAO = 'SET_ORGANIZACAO'
export const SET_ORGANIZACAO_NOME = 'SET_ORGANIZACAO_NOME'
export const SET_DEFAULT_PARCEIRO = 'SET_DEFAULT_PARCEIRO'
export const SET_VINCULAR_PARCEIRO = 'SET_VINCULAR_PARCEIRO'
export const SET_PARCEIRO = 'SET_PARCEIRO'
export const ADD_EMPRESA = 'ADD_EMPRESA'
export const EDIT_EMPRESA = 'EDIT_EMPRESA'
export const SET_EDIT_EMPRESA_INDEX = 'SET_EDIT_EMPRESA_INDEX'
export const ADD_USUARIO = 'ADD_USUARIO'
export const DELETE_EMPRESA = 'DELETE_EMPRESA'
export const EDIT_USUARIO = 'EDIT_USUARIO'
export const SET_EDIT_USUARIO_INDEX = 'SET_EDIT_USUARIO_INDEX'
export const DELETE_USUARIO = 'DELETE_USUARIO'
export const SET_REPRESENTANTE_LEGAL = 'SET_REPRESENTANTE_LEGAL'
export const SET_ID = 'SET_ID'
export const SET_CONTRATO_EXTERNO_LINK = 'SET_CONTRATO_EXTERNO_LINK'
export const ADD_ASSINANTE = 'ADD_ASSINANTE'
export const EDIT_ASSINANTE = 'EDIT_ASSINANTE'
export const SET_EDIT_ASSINANTE_INDEX = 'SET_EDIT_ASSINANTE_INDEX'
export const DELETE_ASSINANTE = 'DELETE_ASSINANTE'

const getDefaultState = () => {
  return {
    id: null,
    actualStep: 1,
    organizacao: null,
    vincularParceiro: false,
    parceiro: {
      id: null,
      nome: null,
    },
    representante_legal: {
      nome: null,
      cpf: null,
      email: null,
      fone: null,
      cargo: null,
    },
    empresas: [],
    usuarios: [],
    assinantes: [],
    editEmpresaIndex: null,
    editUsuarioIndex: null,
    editAssinanteIndex: null,
    contratoExternoLink: null,
  }
}

const state = getDefaultState()

const mutations = {
  [RESET_STATE]: state => Object.assign(state, getDefaultState()),
  [SET_STEP]: (state, step) => (state.actualStep = step),
  [SET_ID]: (state, id) => (state.id = id),
  [SET_ORGANIZACAO]: (state, { nome, id }) => (state.organizacao = { nome: nome || null, id: id || null }),
  [SET_DEFAULT_PARCEIRO]: state => (state.parceiro = { ...getDefaultState().parceiro }),
  [SET_ORGANIZACAO_NOME]: (state, nome) => {
    state.organizacao = {
      id: null,
      nome,
    }
  },
  [SET_VINCULAR_PARCEIRO]: (state, value) => (state.vincularParceiro = value),
  [SET_CONTRATO_EXTERNO_LINK]: (state, value) => (state.contratoExternoLink = value),
  [SET_PARCEIRO]: (state, parceiro) => (state.parceiro = parceiro),

  [ADD_EMPRESA]: (state, empresa) => state.empresas.push(empresa),
  [EDIT_EMPRESA]: (state, { empresa, index }) => (state.empresas[index] = empresa),
  [SET_EDIT_EMPRESA_INDEX]: (state, index) => (state.editEmpresaIndex = index),
  [DELETE_EMPRESA]: (state, index) => state.empresas.splice(index, 1),

  [ADD_USUARIO]: (state, usuario) => state.usuarios.push(usuario),
  [EDIT_USUARIO]: (state, { usuario, index }) => (state.usuarios[index] = usuario),
  [SET_EDIT_USUARIO_INDEX]: (state, index) => (state.editUsuarioIndex = index),
  [DELETE_USUARIO]: (state, index) => state.usuarios.splice(index, 1),

  [ADD_ASSINANTE]: (state, usuario) => state.assinantes.push(usuario),
  [EDIT_ASSINANTE]: (state, { assinante, index }) => (state.assinantes[index] = assinante),
  [SET_EDIT_ASSINANTE_INDEX]: (state, index) => (state.editAssinanteIndex = index),
  [DELETE_ASSINANTE]: (state, index) => state.assinantes.splice(index, 1),

  [SET_REPRESENTANTE_LEGAL]: (state, representante_legal) => (state.representante_legal = { ...representante_legal }),
}

const getters = {
  getOrganizacao: state => state.organizacao || null,
  getActualStep: state => state.actualStep,
  getParceiro: state => state.parceiro,
  getVincularParceiro: state => state.vincularParceiro,
  getEmpresas: state => state.empresas,
  getEditEmpresaIndex: state => state.editEmpresaIndex,
  getUsuarios: state => state.usuarios,
  getEditUsuarioIndex: state => state.editUsuarioIndex,
  getAssinantes: state => state.assinantes,
  getEditAssinanteIndex: state => state.editAssinanteIndex,
  getRepresentanteLegal: state => state.representante_legal,
  getContratoExternoLink: state => state.contratoExternoLink,
}

const actions = {
  resetAgendamento: ({ commit }) => {
    commit(RESET_STATE)
  },
  setVincularParceiro: ({ commit }, value) => {
    commit(SET_VINCULAR_PARCEIRO, value)
    if (!value) {
      commit(SET_DEFAULT_PARCEIRO)
    }
  },
  setEmpresa: ({ commit, getters }, empresa) => {
    const index = getters.getEditEmpresaIndex
    if (index !== null) {
      commit(EDIT_EMPRESA, { empresa, index })
    } else {
      commit(ADD_EMPRESA, empresa)
    }
  },
  setUsuario: ({ commit, getters }, usuario) => {
    const index = getters.getEditUsuarioIndex
    if (index >= 0) {
      commit(EDIT_USUARIO, { usuario, index })
    } else {
      commit(ADD_USUARIO, usuario)
    }
  },
  setAssinante: ({ commit, getters }, assinante) => {
    const index = getters.getEditAssinanteIndex
    if (index !== null) {
      commit(EDIT_ASSINANTE, { assinante, index })
    } else {
      commit(ADD_ASSINANTE, assinante)
    }
  },
  submitContrato: async ({ state, dispatch, commit }) => {
    function buildEmpresa(empresa) {
      let dados_plano = {}
      if (!empresa.faturar_matriz) {
        dados_plano = {
          tipo: empresa.plano.tipo,
          ...empresa.plano.dados_plano,
          preco_clinica: empresa.preco_clinica,
          ...empresa.faturamento,
          preco_telemedicina: empresa.selectedExames.find(exame => exame.id === 0)
            ? empresa.selectedExames.find(exame => exame.id === 0).preco
            : empresa.plano.preco_telemedicina || null,
        }
      }
      return {
        faturar_matriz: empresa.faturar_matriz || null,
        cnpj: extractOnlyNumbers(empresa.cnpj),
        cpf: extractOnlyNumbers(empresa.cpf),
        caepf: extractOnlyNumbers(empresa.caepf),
        tipo_empresa: empresa.tipo_empresa,
        cnae_principal_codigo: empresa.cnae_principal_codigo,
        endereco_bairro: empresa.endereco_bairro,
        endereco_cep: empresa.endereco_cep,
        endereco_id_cidade: empresa.endereco_id_cidade,
        endereco_numero: empresa.endereco_numero,
        endereco_rua: empresa.endereco_rua,
        endereco_uf: empresa.endereco_uf,
        razao_social: empresa.razao_social,
        segmento: empresa.segmento,
        plano: {
          faturar_matriz: empresa.faturar_matriz || null,
          ...dados_plano,
        },
        modulos: [...empresa.selectedModulos],
        produtos: [...empresa.selectedProdutos],
        exames: [...empresa.selectedExames.filter(exame => exame.id !== 0)],
      }
    }

    function buildUsuarios(usuarios) {
      return usuarios.map(usuario => {
        const empresas = usuario.empresas.map(empresa => extractOnlyNumbers(empresa.cnpj))
        if (usuario.id) {
          return {
            id: usuario.id,
            empresas,
          }
        }
        return {
          ...usuario,
          empresas: [...empresas],
        }
      })
    }

    function buildOrganizacao(organizacao) {
      if (organizacao.id) {
        return { id: organizacao.id }
      }
      return { nome: organizacao.nome }
    }
    function buildAssinantes(assinantes) {
      return assinantes.map(assinante => {
        return {
          ...assinante,
          qualificacao: assinante.qualificacao.value,
          status: 'pendente',
        }
      })
    }

    try {
      let matrizes = state.empresas.filter(empresa => empresa.matriz)
      matrizes = matrizes.map(matriz => {
        const filiais = state.empresas.filter(
          empresa => matriz.cnpj && extractOnlyNumbers(empresa.cnpjMatriz) === extractOnlyNumbers(matriz.cnpj)
        )
        return {
          ...buildEmpresa(matriz),
          filiais: filiais.map(filial => buildEmpresa(filial)),
        }
      })

      const negocio = {
        organizacao: {
          ...buildOrganizacao(state.organizacao),
        },
        representante_legal: {
          ...state.representante_legal,
        },

        empresas: [...matrizes],
        usuarios: [...buildUsuarios(state.usuarios)],
        assinantes: [...buildAssinantes(state.assinantes)],
        contrato_externo_link: state.contratoExternoLink,
      }
      let data = null
      if (state.id) {
        data = await negocioService.updateNovoNegocio(negocio, state.id)
      } else {
        data = await negocioService.criarNovoNegocio(negocio)
      }
      commit(RESET_STATE)
      return data.data
    } catch ({ response }) {
      let message = ''
      if (response.data) {
        message = response.data.message
      } else {
        message = 'Houve um erro interno, contate o administrador do sistema'
      }
      console.log(response)
      dispatch('dashboard/showErrorOnSnackbar', message, { root: true })
    }
  },
  assignNegocio: ({ commit, state }, negocio) => {
    commit(RESET_STATE)
    commit(SET_ID, negocio.id)
    commit(SET_ORGANIZACAO, negocio.organizacao)
    commit(SET_REPRESENTANTE_LEGAL, negocio.representanteLegal)
    commit(SET_REPRESENTANTE_LEGAL, negocio.representanteLegal)
    commit(SET_CONTRATO_EXTERNO_LINK, negocio.contrato_externo_link)

    negocio.empresas.forEach(empresa => {
      commit(ADD_EMPRESA, {
        cnpj: extractOnlyNumbers(empresa.cnpj),
        cpf: extractOnlyNumbers(empresa.cpf),
        caepf: extractOnlyNumbers(empresa.caepf),
        matriz: true,
        segmento: empresa.segmento,
        tipo_empresa: empresa.tipo_empresa,
        pcmso: null,
        funcionarios: null,
        preco_clinica: empresa.plano.preco_clinica,
        faturar_matriz: false,
        cnae_principal_codigo: empresa.cnae_principal_codigo,
        endereco_bairro: empresa.endereco_bairro,
        endereco_cep: empresa.endereco_cep,
        endereco_id_cidade: empresa.endereco_id_cidade,
        endereco_numero: empresa.endereco_numero,
        endereco_rua: empresa.endereco_rua,
        endereco_uf: empresa.endereco_uf,
        razao_social: empresa.razao_social,
        plano: {
          tipo: empresa.plano.tipo,
          preco_telemedicina: empresa.plano.preco_telemedicina || null,
          dados_plano: {
            preco: empresa.plano.preco || null,
            qtde_assinaturas: empresa.plano.qtde_assinaturas || null,
          },
        },
        faturamento: {
          email_faturamento: empresa.plano.email_faturamento,
          emailExiste: empresa.plano.emailExiste,
          responsavel: empresa.plano.responsavel,
          dia_vencimento: empresa.plano.dia_vencimento,
        },
        cnpjMatriz: null,
        empresaExiste: false,
        temUnidades: false,
        selectedExames: [...empresa.exames],
        selectedProdutos: [...empresa.produtos],
        selectedModulos: [...empresa.modulos],
      })

      empresa.filiais.forEach(filial => {
        let dados_plano = {}
        if (!filial.faturar_matriz) {
          dados_plano = {
            plano: {
              tipo: empresa.plano.tipo,
              preco_telemedicina: empresa.plano.preco_telemedicina || null,
              dados_plano: {
                preco: empresa.plano.preco || null,
                qtde_assinaturas: empresa.plano.qtde_assinaturas || null,
              },
            },
            faturamento: {
              email_faturamento: empresa.plano.email_faturamento,
              emailExiste: empresa.plano.emailExiste,
              responsavel: empresa.plano.responsavel,
              dia_vencimento: empresa.plano.dia_vencimento,
            },
          }
        }
        commit(ADD_EMPRESA, {
          cnpj: filial.cnpj,
          matriz: false,
          segmento: filial.segmento,
          pcmso: null,
          funcionarios: null,
          cnae_principal_codigo: filial.cnae_principal_codigo,
          endereco_bairro: filial.endereco_bairro,
          endereco_cep: filial.endereco_cep,
          endereco_id_cidade: filial.endereco_id_cidade,
          endereco_numero: filial.endereco_numero,
          endereco_rua: filial.endereco_rua,
          endereco_uf: filial.endereco_uf,
          razao_social: filial.razao_social,
          preco_clinica: filial.plano.preco_clinica,
          faturar_matriz: filial.faturar_matriz,
          cnpjMatriz: extractOnlyNumbers(empresa.cnpj),
          empresaExiste: false,
          temUnidades: false,
          selectedExames: [...filial.exames],
          selectedProdutos: [...filial.produtos],
          selectedModulos: [...filial.modulos],
          ...dados_plano,
        })
      })
    })
    negocio.usuarios.forEach(usuario => {
      commit(ADD_USUARIO, {
        ...usuario,
        empresas: usuario.empresas.map(cnpj => {
          return {
            cnpj,
            razao_social: state.empresas.find(empresa => empresa.cnpj === cnpj).razao_social,
          }
        }),
      })
    })

    negocio.assinantes.forEach(assinante => {
      commit(ADD_ASSINANTE, {
        ...assinante,
        qualificacao: {
          title: TIPOS_ASSINANTES[assinante.qualificacao],
          value: assinante.qualificacao,
        },
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
