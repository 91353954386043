import Axios from 'axios'
import { isNil } from 'lodash'

export default {
  searchFuncoes: (idEmpresa, inativas = true) => Axios.get(`/empresas/${idEmpresa}/funcoes`, { params: { inativas } }),
  searchCbos: () => Axios.get('/funcoes/cbos'),
  findById: (idEmpresa, idFuncao) => Axios.get(`/funcoes/${idFuncao}`),
  save: (funcao, idEmpresa) => {
    const exames = funcao.exames.map(exame => {
      const tipos = exame.tipos.map(tipo => tipo.value)
      return { id: exame.value, validade: exame.validade, tipos }
    })

    const params = {
      nome: funcao.name,
      status: funcao.status,
      id_empresa: idEmpresa,
      riscos: funcao.riscos,
      exames,
      descricao: funcao.description,
      id_cbo: funcao.cbo,
      periculosidade: funcao.periculosidade,
      insalubridade: funcao.insalubridade,
      nocividade: funcao.nocividade
    }

    if (!isNil(funcao.id)) {
      // atualiza
      return Axios.put(`/funcoes/${funcao.id}`, params)
    }

    return Axios.post('/funcoes', params)
  },
  updateStatus: (idFuncao, status) => Axios.patch(`/funcoes/${idFuncao}/status`, { status }),
  getRiscos: idFuncao => Axios.get(`/funcoes/${idFuncao}/riscos`),
  exportRiscos: ({ riscos, idsFuncoes }) => Axios.post('/funcoes/exportar-riscos', { riscos, idsFuncoes }),
}
