import Axios from 'axios'
import { formatDate } from '@/core/utils/formatter'

export default {
  search: (idFuncionario = null, idCidade = null) =>
    Axios.get('/clinicas', {
      params: {
        funcionario: idFuncionario,
        cidade: idCidade,
      },
    }),
  getAgendamentos: token => {
    return Axios.get(`/clinicas/${encodeURIComponent(token)}/agendamentos`)
  },
  finalizarAso: (token, idAso, formData) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/finalizar`, formData)
  },
  definirHorario: ({ id_aso, dt_agendamento, orientacoes }, token) =>
    Axios.patch(`/clinicas/${encodeURIComponent(token)}/asos/${id_aso}/definir-horario`, {
      dt_agendamento: formatDate(dt_agendamento),
      orientacoes,
    }),
  openFichaClinica: (token, idAso) =>
    Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/ficha-clinica/download`),
  openAso: (token, idAso) => Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/arquivo-retorno/download`),
}
