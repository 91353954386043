<template>
  <v-main>
    <slot name="navigation"></slot>
    <slot name="header"></slot>
    <slot></slot>
    <slot name="dialogs"></slot>
  </v-main>
</template>

<script>
export default {}
</script>
