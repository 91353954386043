import Axios from 'axios'

export default {
  getInfoAgendamento: (idFuncionario, tipoExame) =>
    Axios.get(`/funcionarios/${idFuncionario}/agendamento/${tipoExame}`),
  getAgendamentos: idFuncionario => Axios.get(`/funcionarios/${idFuncionario}/agendamentos`),
  agendamentosIndisponiveis: idFuncionario => Axios.get(`/funcionarios/${idFuncionario}/agendamentos/indisponiveis`),
  getAgendaMedica: (data, idFuncionario) => Axios.get(`/horarios/${data}/funcionario/${idFuncionario}`),
  openAso: idAso => Axios.get(`/asos/${idAso}/arquivo-retorno/download`),
}
