import clinicaService from '@/services/clinica'

export const SET_CLINICAS = 'SET_CLINICAS'
export const SET_CIDADE = 'SET_CIDADE'

const state = {
  clinicas: [],
  cidade: {}
}

const mutations = {
  [SET_CLINICAS]: (state, payload) => {
    state.clinicas = payload
  },
  [SET_CIDADE]: (state, payload) => {
    state.cidade = payload
  }
}

const actions = {
  async search({ commit, dispatch }, { idFuncionario, idCidade }) {
    try {
      const response = await clinicaService.search(idFuncionario, idCidade)

      if (response.data.cidade) {
        commit(SET_CIDADE, response.data.cidade)
      }
      commit(SET_CLINICAS, response.data.clinicas)
    } catch (error) {
      const { status } = error.response

      if (status === 400) {
        dispatch('dashboard/showErrorOnSnackbar', error.response.data.message, { root: true })
      } else {
        dispatch('dashboard/showErrorOnSnackbar', 'Erro! Entre em contato com nosso time', { root: true })
      }
    }
  }
}

export default {
  namespaced: true, state, mutations, actions
}
