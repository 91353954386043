import Vue from 'vue'
import './plugins/axios'
import store from '@/store/index'
import { datadogRum } from '@datadog/browser-rum'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/shepherd'
import './core'
import Datadog from './plugins/datadog'

Vue.config.productionTip = false
Vue.use(Datadog, datadogRum)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App, { ref: 'app' }),
}).$mount('#app')
