import { isEmpty } from 'lodash'
import faturamentoService from '@/services/faturamento'

export const SET_FATURAS = 'SET_FATURAS'
export const SET_PAGE = 'SET_PAGE'
export const SET_PER_PAGE = 'SET_PER_PAGE'
export const SET_COLUMN = 'SET_COLUMN'

export const SET_LOADING = 'SET_LOADING'
export const RESET_PAGE = 'RESET_PAGE'

const state = {
  busca: {
    faturas: [],
    page: 1,
    perPage: 5,
    lastPage: 0,
    total: 0,
    loading: false,
    filtros: []
  }
}

const mutations = {
  [SET_FATURAS]: (state, { data, total }) => {
    state.busca.faturas = data
    state.busca.total = parseInt(total, 10)

    let lastPage = total / state.busca.perPage
    lastPage = lastPage < 1 ? 1 : Math.ceil(lastPage)

    state.busca.lastPage = lastPage
  },
  [SET_PAGE]: (state, payload) => {
    state.busca.page = payload
  },
  [SET_PER_PAGE]: (state, payload) => {
    state.busca.perPage = payload
  },
  [SET_COLUMN]: (state, payload) => {
    let column
    switch (payload) {
      case 'nome':
        column = 'pessoas.nome'
        break
      case 'matriz_nome':
        column = 'matrizes.razao_social'
        break
      case 'unidade_nome':
        column = 'empresas.razao_social'
        break
      default:
        column = 'faturas.id_funcionario'
        break
    }
    state.busca.column = column
  },
  [SET_LOADING]: (state, payload) => {
    state.busca.loading = payload
  },
  [RESET_PAGE]: state => {
    state.busca.page = 1
  }
}

const getters = {
  hasFaturas: state => !isEmpty(state.busca.faturas),
  faturas: state => state.busca.faturas,
  page: state => state.busca.page,
  lastPage: state => state.busca.lastPage,
  total: state => state.busca.total,
  perPage: state => state.busca.perPage,
  column: state => state.busca.column,
  relation: state => state.busca.relation,
  desc: state => state.busca.desc,
  filtros: state => state.busca.filtros,
  loading: state => state.busca.loading
}

const actions = {
  async buscaFaturasAdmin({ commit, getters }, params) {
    const response = await faturamentoService.searchAdmin({
      page: getters.page,
      perPage: getters.perPage,
      column: getters.column,
      relation: getters.relation,
      desc: getters.desc,
      filters: getters.filtros,
      ...params
    })

    commit(SET_FATURAS, {
      data: response.data.data,
      total: response.data.total
    })
  },
  async buscaFaturasEmpresa({ commit, getters }, empresa) {
    const response = await faturamentoService.searchEmpresa(empresa, {
      page: getters.page,
      perPage: getters.perPage,
      column: getters.column,
      relation: getters.relation,
      desc: getters.desc,
      filters: getters.filtros
    })
    commit(SET_FATURAS, {
      data: response.data.data,
      total: response.data.total
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
