import { isNil } from 'lodash'
import Vue from 'vue'
import formatter from '@/core/utils/formatter'

Vue.filter('cpf', formatter.formatCPF)
Vue.filter('caepf', formatter.formatCaepf)
Vue.filter('rg', formatter.formatRG)
Vue.filter('cnpj', formatter.formatCnpj)
Vue.filter('cep', formatter.formatCep)
Vue.filter('telefone', formatter.formatTelefone)
Vue.filter('telefone10', formatter.formatTelefone10)
Vue.filter('telefone11', formatter.formatTelefone11)
Vue.filter('dateBr', v => formatter.formatDate(v, 'YYYY-MM-DD', 'DD/MM/YYYY'))
Vue.filter('yearMonthBr', v => formatter.formatDate(v, 'YYYY-MM-DD', 'MM/YYYY'))
Vue.filter('preco', v => formatter.formatPreco(v))
Vue.filter('sexo', v => {
  if (isNil(v)) {
    return null
  }
  return v === 'M' ? 'Masculino' : 'Feminino'
})
