import Vue from 'vue'
import Vuex from 'vuex'
import Crypto from 'crypto-js'
// import uuid from 'uuid'
import VuexPersistence from 'vuex-persist'
import autenticacao from './modules/autenticacao'
import dashboard from './modules/dashboard'
import empresa from './modules/empresa'
import funcionario from './modules/funcionario'
import faturamento from './modules/faturamento'
import funcao from './modules/funcao'
import setor from './modules/setor'
import exame from './modules/exame'
import risco from './modules/risco'
import common from './modules/common'
import agendamento from './modules/agendamento'
import clinica from './modules/clinica'
import negocio from './modules/negocio'
import location from './modules/location'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const storageKey = 'limer'
const encryptionToken = process.env.VUE_APP_ENCRYPTATION_TOKEN

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      // Get the store from local storage.
      const store = window.localStorage.getItem(storageKey)
      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, encryptionToken)
          return JSON.parse(bytes.toString(Crypto.enc.Utf8))
        } catch (e) {
          // The store will be reset if decryption fails.
          window.localStorage.removeItem(storageKey)
        }
      }

      return null
    },
    setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto.AES.encrypt(value, encryptionToken).toString()

      // Save the encrypted store in local storage.
      return window.localStorage.setItem(storageKey, store)
    },
    removeItem: () => window.localStorage.removeItem(storageKey),
  },
  // storage: window.localStorage,
  modules: ['autenticacao', 'negocio'],
})

export default new Vuex.Store({
  modules: {
    autenticacao,
    dashboard,
    empresa,
    funcionario,
    faturamento,
    exame,
    common,
    risco,
    setor,
    funcao,
    agendamento,
    clinica,
    location,
    negocio,
  },
  strict: debug,
  plugins: [vuexLocal.plugin],
})
