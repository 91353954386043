// const isProd = process.env.VUE_APP_ENV === 'PROD'

const Datadog = {
  install(Vue, datadogRum) {
    Vue.prototype.$initDatadog = function () {
        datadogRum.init({
            applicationId: '7627ab1b-83ec-48fb-b0d5-cc00821bed83',
            clientToken: 'pub989156b5c9f0e39818bd8750c7553f6f',
            site: 'datadoghq.com',
            service: 'plataforma-front',
            env: process.env.VUE_APP_ENV.toLowerCase(),
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
    }

    Vue.prototype.$setDatadogUser = function (id, nome, email) {
      datadogRum.setUser({
        id,
        name: nome,
        email
      })
    }

    Vue.prototype.$getDatadogUser = function () {

    }

    Vue.prototype.$logoutDatadogUser = function () {
      datadogRum.clearUser()
    }
  }
}

export default Datadog
