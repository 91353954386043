import Axios from 'axios'
import { isNil } from 'lodash'

export default {
  buscaCidades: estado => {
    if (isNil(estado)) {
      return { data: [] }
    }
    return Axios.get('cidades', {
      params: { uf: estado }
    })
  },
  buscaCidadeComClinica: () => Axios.get('/cidades?clinicas=true')
}
