import Cookies from 'js-cookie'
import { isNil } from 'lodash'
import Axios from 'axios'
// import jsonWebToken from 'jsonwebtoken'

const getTempoExpiracao = () => {
  const date = new Date()
  date.setSeconds(date.getSeconds() + 43200)
  return date
}

export default {
  login: (username, password) => {
    const params = { username, password }
    return Axios.post('/login', params, { withCredentials: true })
  },
  requestNewPassword: username => Axios.post('/authentication/senha/solicitarNova', { email: username }),
  changePassword: (hash, password) => Axios.post('/authentication/senha/alterar', { hash, senha: password }),
  saveCookie: (token) => {
    // const jwtDecrypted = jsonWebToken.decode(token)
    Cookies.set('user_token', token, { expires: getTempoExpiracao() })
  },
  logout: () => {
    Cookies.remove('user_token')
    return Axios.post('/logout', null, { withCredentials: true })
  },
  isAuthenticated: () => !isNil(Cookies.get('user_token')),
  getToken: () => Cookies.get('user_token')
}
