import Axios from 'axios'

export default {
  search: (withInativos = false) => Axios.get('/riscos', { params: { withInativos } }),
  searchByIdModule: (currentModule, idModule) => Axios.get(`/riscos/${currentModule}/${idModule}`),
  searchById: idRisco => Axios.get(`/admin/riscos/${idRisco}`),
  edit: async risco => {
    const params = {
      id_risco: risco.id_risco,
      nome: risco.nome,
      codigo_esocial: risco.codigo_esocial,
      tipo: risco.tipo,
      status: risco.status,
    }
    return Axios.put('/riscos', params)
  },
  create: async risco => {
    const params = {
      nome: risco.nome,
      codigo_esocial: risco.codigo_esocial,
      tipo: risco.tipo,
      status: risco.status,
    }
    return Axios.post('/riscos', params)
  },
  searchCodigosEsocial: () => Axios.get('/riscos/codigos-esocial'),
}
