import Axios from 'axios'
import { isNil } from 'lodash'

export default {
  searchSetores: (idEmpresa, inativas = true) => Axios.get(`/empresas/${idEmpresa}/setores`, { params: { inativas } }),
  searchSetor: idSetor => Axios.get(`/setores/${idSetor}`),
  save: (setor, idEmpresa) => {
    const exames = setor.exames.map(exame => {
      const tipos = exame.tipos.map(tipo => tipo.value)
      return { id: exame.value, validade: exame.validade, tipos }
    })

    const params = {
      nome: setor.name,
      status: setor.status,
      id_empresa: idEmpresa,
      riscos: setor.riscos,
      exames,
      descricao: setor.description,
      perigos: setor.perigos,
      agente_fator_riscos: setor.agente_fator_riscos,
      consequencia_riscos: setor.consequencia_riscos,
      descricao_epis: setor.descricao_epis,
      descricao_epcs: setor.descricao_epcs,
      funcoes: setor.funcoes,
      insalubridade: setor.insalubridade,
      periculosidade: setor.periculosidade,
      nocividade: setor.nocividade,
    }

    if (!isNil(setor.id)) {
      // atualiza
      return Axios.put(`/setores/${setor.id}`, params)
    }

    return Axios.post('/setores', params)
  },
  updateStatus: (idFuncao, status) => Axios.patch(`/setores/${idFuncao}/status`, { status }),
  getRiscos: idSetor => Axios.get(`/setores/${idSetor}/riscos`),
  exportRiscos: ({ riscos, idsSetores }) => Axios.post('/setores/exportar-riscos', { riscos, idsSetores }),
  exportDadosPgr: ({ idsSetores, dadosPgr }) => Axios.put('/setores/exportar/dados-pgr', { idsSetores, dadosPgr }),
}
