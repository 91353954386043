import Axios from 'axios'

const url = '/novos-negocios'

export default {
  criarNovoNegocio: negocio => Axios.post(`${url}`, negocio),
  updateNovoNegocio: (negocio, id) => Axios.put(`${url}/${id}`, negocio),
  inativeNovoNegocio: id => Axios.delete(`${url}/${id}`),
  list: (page, per_page, filtros, options) => {
    const filtroParams = {}

    filtros.forEach(
      filtro =>
        (filtroParams[filtro.column] = filtro.value.map(
          value =>
            value
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          // eslint-disable-next-line function-paren-newline
        ))
    )

    let { sortDesc, sortBy } = options
    sortBy = sortBy[0] ? sortBy[0] : 'dt_atualizacao'
    sortDesc = sortDesc[0] ? 'asc' : 'desc'

    const { itemsPerPage } = options
    per_page = itemsPerPage

    return Axios.get(`${url}`, {
      params: { page, per_page, order_by: sortBy, order: sortDesc, ...filtroParams },
    })
  },
  showPlano: id => Axios.get(`${url}/plano/${encodeURIComponent(id)}`),
  showContrato: id => Axios.get(`${url}/contrato/${id}`),
  showEmpresasContratoAtivo: id => Axios.get(`${url}/empresas-contrato-ativo/${id}`),
  show: id => Axios.get(`${url}/${id}`),
  sendTokenEmail: id => Axios.post(`${url}/plano/${encodeURIComponent(id)}/enviar-token`),
  sendContratoEmail: (id, email) =>
    Axios.post(`${url}/${encodeURIComponent(id)}/reenviar-email`, {
      email,
    }),
  finalizar: ({ token, latitude, longitude }) => Axios.post(`${url}/finalizar`, { token, latitude, longitude }),
  verificaEmpresa: cnpjCpf => Axios.get(`${url}/verifica-empresa/${cnpjCpf}`),
  reactivate: id => Axios.patch(`${url}/${id}/reativar`),
  anexarEmpresas: negocio => Axios.post(`${url}/anexar-empresas`, negocio),
}
