/* eslint-disable no-param-reassign */
import axios from 'axios'
import { get } from 'lodash'
import autenticacaoService from '@/services/autenticacao'
import medicoService from '@/services/medico'
import store from '@/store/index'
import router from '@/router'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

axios.interceptors.request.use(config => {
  if (config.url.indexOf('authentication/') === -1) {
    if (medicoService.isAuthenticated()) {
      config.headers['Authorization-Medico'] = `Bearer ${medicoService.getToken()}`
    } else {
      config.headers.Authorization = `Bearer ${autenticacaoService.getToken()}`
    }
  }

  return config
})

axios.interceptors.response.use(
  response => response,
  error => {
    if (get(error, 'response.status') === 401 && router.currentRoute.name !== 'login') {
      // qnd token estiver expirado, desloga automaticamente
      store.dispatch('autenticacao/logout', router)

      // Caso seja login médico, apaga o token expirado
      medicoService.removeToken()
    }
    console.error(error)
    return Promise.reject(error)
  }
)
