import locationService from '@/services/location'

export const SET_CIDADES_COM_CLINICA = 'SET_CIDADES_COM_CLINICA'
export const SET_LOADING = 'SET_LOADING'

const state = {
  cidadesComClinica: [],
  loading: false
}

const mutations = {
  [SET_CIDADES_COM_CLINICA]: (state, payload) => {
    state.cidadesComClinica = payload
  },
  [SET_LOADING]: (state, payload) => {
    state.loading = payload
  }
}

const actions = {
  async searchCidadesComClinica({ dispatch, commit }) {
    commit('SET_LOADING', true)
    try {
      const response = await locationService.buscaCidadeComClinica()
      commit(SET_CIDADES_COM_CLINICA, response.data)
    } catch (error) {
      const { status } = error.response

      if (status === 400) {
        dispatch('dashboard/showErrorOnSnackbar', error.response.data.message, { root: true })
      } else {
        dispatch('dashboard/showErrorOnSnackbar', 'Erro! Entre em contato com nosso time', { root: true })
      }
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true, state, mutations, actions
}
