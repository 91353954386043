import { isEmpty } from 'lodash'
import funcionarioService from '@/services/funcionario'

export const SET_FUNCIONARIOS = 'SET_FUNCIONARIOS'
export const SET_FILTROS = 'SET_FILTROS'
export const SET_PAGE = 'SET_PAGE'
export const SET_PER_PAGE = 'SET_PER_PAGE'
export const SET_COLUMN = 'SET_COLUMN'
export const SET_DESC = 'SET_DESC'
export const SET_LOADING = 'SET_LOADING'
export const ADD_FUNCIONARIO = 'ADD_FUNCIONARIO'
export const RESET_PAGE = 'RESET_PAGE'

const state = {
  busca: {
    funcionarios: [],
    page: 1,
    perPage: 30,
    lastPage: 0,
    total: 0,
    column: 'funcionarios.id_funcionario',
    relation: '',
    desc: 0,
    loading: false,
    filtros: [],
  },
}

const mutations = {
  [SET_FUNCIONARIOS]: (state, { data, headers }) => {
    state.busca.funcionarios = data

    const total = parseInt(headers['total-items'], 10)
    state.busca.total = total

    let lastPage = total / state.busca.perPage
    lastPage = lastPage < 1 ? 1 : Math.ceil(lastPage)

    state.busca.lastPage = lastPage
  },
  [SET_FILTROS]: (state, payload) => {
    state.busca.filtros = payload
  },
  [SET_PAGE]: (state, payload) => {
    state.busca.page = payload
  },
  [SET_PER_PAGE]: (state, payload) => {
    state.busca.perPage = payload
  },
  [SET_COLUMN]: (state, payload) => {
    let column
    switch (payload) {
      case 'nome':
        column = 'pessoas.nome'
        break
      case 'matriz_nome':
        column = 'matrizes.razao_social'
        break
      case 'unidade_nome':
        column = 'empresas.razao_social'
        break
      default:
        column = 'funcionarios.id_funcionario'
        break
    }
    state.busca.column = column
  },
  [SET_DESC]: (state, payload) => {
    state.busca.desc = payload
  },
  [SET_LOADING]: (state, payload) => {
    state.busca.loading = payload
  },
  [ADD_FUNCIONARIO]: (state, payload) => {
    const funcionarios = [payload, ...state.busca.funcionarios]

    state.busca.funcionarios = funcionarios
  },
  [RESET_PAGE]: state => {
    state.busca.page = 1
  },
}

const getters = {
  hasFuncionarios: state => !isEmpty(state.busca.funcionarios),
  funcionarios: state => state.busca.funcionarios,
  page: state => state.busca.page,
  lastPage: state => state.busca.lastPage,
  total: state => state.busca.total,
  perPage: state => state.busca.perPage,
  column: state => state.busca.column,
  relation: state => state.busca.relation,
  desc: state => state.busca.desc,
  filtros: state => state.busca.filtros,
  loading: state => state.busca.loading,
}

const actions = {
  async save({}, funcionario) {
    await funcionarioService.insert(funcionario)
  },
  async buscaFuncionarios({ commit, getters }) {
    const response = await funcionarioService.search({
      page: getters.page,
      perPage: getters.perPage,
      column: getters.column,
      relation: getters.relation,
      desc: getters.desc,
      filters: getters.filtros,
    })

    commit(SET_FUNCIONARIOS, {
      data: response.data,
      headers: response.headers,
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
