import autenticacaoService from '@/services/autenticacao'

export const SAVE_SESSION = 'SAVE_SESSION'

const state = {
  username: '',
  email: null,
  tipoUsuario: null,
  funcaoSetorPermission: null,
  enableEmpresaCreation: null,
  blockUsersCreation: null,
  disableFuncionarios: null,
  enableIndicadores: null,
  enableCertificadoDigital: null,
  enableESocial: null,
  enableAtestado: null,
  enableMigrarFuncionario: null,
  enableVisualizarContrato: null,
  enableFaturamento: null,
  enableConverterEmpresas: null,
  parceiro: null,
  enableUnidadeCreation: null,
  enableMudancaSenha: null,
}

const mutations = {
  [SAVE_SESSION]: (state, user) => {
    state.id_usuario = user.id_usuario
    state.username = user.usuario
    state.tipoUsuario = user.tipo_usuario
    state.funcaoSetorPermission = user.funcao_setor_permission
    state.enableEmpresaCreation = user.enable_create_empresas
    state.blockUsersCreation = user.block_create_users
    state.disableFuncionarios = user.disable_funcionarios
    state.disableAgendamentos = user.disable_agendamentos
    state.enableIndicadores = user.enable_indicadores
    state.enableCertificadoDigital = user.enable_certificado_digital
    state.enableESocial = user.enable_esocial
    state.enableAtestados = user.enable_atestados
    state.email = user.email
    state.enableMigrarFuncionario = user.enable_migrar_funcionario
    state.enableVisualizarContrato = user.enable_visualizar_contrato
    state.enableFaturamento = user.enable_faturamento
    state.enableConverterEmpresas = user.enable_converter_empresas
    state.isFromVero = user.is_from_vero
    state.isFromBelfort = user.is_from_belfort
    state.isFromSemework = user.is_from_semework
    state.enableInventarioRiscos = user.enable_inventario_riscos
    state.parceiro = user.parceiro
    state.certificadosExpirados = user.certificados_expirados
    state.enableUnidadeCreation = user.enable_create_unidades
    state.enableMudancaSenha = user.enable_mudanca_senha
  },
}

const getters = {
  id_usuario: state => state.id_usuario,
  username: state => state.username,
  email: state => state.email,
  tipoUsuario: state => state.tipoUsuario,
  funcaoSetorPermission: state => state.funcaoSetorPermission,
  enableEmpresaCreation: state => state.enableEmpresaCreation,
  blockUsersCreation: state => state.blockUsersCreation,
  disableFuncionarios: state => state.disableFuncionarios,
  disableAgendamentos: state => state.disableAgendamentos,
  enableIndicadores: state => state.enableIndicadores,
  enableCertificadoDigital: state => state.enableCertificadoDigital,
  enableESocial: state => state.enableESocial,
  enableAtestados: state => state.enableAtestados,
  enableMigrarFuncionario: state => state.enableMigrarFuncionario,
  enableVisualizarContrato: state => state.enableVisualizarContrato,
  enableFaturamento: state => state.enableFaturamento,
  enableConverterEmpresas: state => state.enableConverterEmpresas,
  isFromVero: state => state.isFromVero,
  isFromBelfort: state => state.isFromBelfort,
  isFromSemework: state => state.isFromSemework,
  enableInventarioRiscos: state => state.enableInventarioRiscos,
  parceiro: state => state.parceiro,
  certificadosExpirados: state => state.certificadosExpirados,
  enableUnidadeCreation: state => state.enableUnidadeCreation,
  enableMudancaSenha: state => state.enableMudancaSenha
}

const actions = {
  login: async ({ commit }, { username, password }) => {
    try {
      const response = await autenticacaoService.login(username, password)
      autenticacaoService.saveCookie(response.data.token)
      commit(SAVE_SESSION, response.data.user)
      return Promise.resolve(true)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  logout: async ({}, router) => {
    await autenticacaoService.logout()
    if (router) {
      // if router exists redirect to unauthorized
      router.push({ name: 'sessionExpired' })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
