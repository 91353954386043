import riscoService from '@/services/risco'
import funcaoService from '@/services/funcao'
import setorService from '@/services/setor'

export const SET_CURRENT_RISCOS = 'SET_CURRENT_RISCOS'
export const SET_ALL_RISCOS = 'SET_CODIGOS_ESOCIAL'
export const SET_CODIGOS_ESOCIAL = 'SET_ALL_RISCOS'
export const SET_EDIT_RISCO_INDEX = 'SET_EDIT_RISCO_INDEX'
export const RESET_STATE = 'RESET_STATE'
export const ADD_RISCO = 'ADD_RISCO'
export const EDIT_RISCO = 'EDIT_RISCO'
export const SET_SELECTED_RISCOS = 'SET_SELECTED_RISCOS'
export const SELECT_RISCO = 'SELECT_RISCO'
export const UNSELECT_RISCO = 'UNSELECT_RISCO'
export const RESET_SELECTED = 'RESET_SELECTED'
export const SET_EXPORT_MODULES = 'SET_EXPORT_MODULES'
export const RESET_EXPORT_MODULES = 'RESET_EXPORT_MODULES'

const getDefaultState = () => {
  return {
    currentRiscos: [],
    allRiscos: [],
    codigosRiscosEsocial: [],
    editRiscoIndex: null,
    selectedRiscos: [],
    modulesToExport: [],
  }
}

const state = getDefaultState()

const mutations = {
  [RESET_STATE]: state => Object.assign(state, getDefaultState()),
  [SET_CURRENT_RISCOS]: (state, riscos) => {
    state.currentRiscos = riscos.map(r => ({ value: r.id_risco, label: r.nome, data: { ...r } }))
  },
  [SET_ALL_RISCOS]: (state, riscos) => {
    state.allRiscos = riscos.map(r => ({
      value: r.id_risco,
      label: r.nome,
      type: r.tipo,
      codigo_esocial: r.codigo_esocial,
      status: r.status,
    }))
  },
  [SET_CODIGOS_ESOCIAL]: (state, riscos) => {
    state.codigosRiscosEsocial = riscos.map(r => ({ value: r.codigo_esocial, label: r.nome }))
  },
  [SET_EDIT_RISCO_INDEX]: (state, index) => (state.editRiscoIndex = index),
  [SET_SELECTED_RISCOS]: (state, riscos) => (state.selectedRiscos = riscos),

  [SELECT_RISCO]: (state, risco) => {
    const formattedRiscos = risco.map(row => {
      const { data, value, label } = row
      return {
        data: { ...data, ativo: true },
        value,
        label,
      }
    })
    state.selectedRiscos.push(...formattedRiscos)
  },
  [UNSELECT_RISCO]: (state, index) => state.selectedRiscos.splice(index, 1),
  [RESET_SELECTED]: state => (state.selectedRiscos = []),

  [ADD_RISCO]: (state, risco) => state.currentRiscos.push(risco),
  [EDIT_RISCO]: (state, { risco, index }) => state.currentRiscos.splice(index, 1, risco),
  [SET_EXPORT_MODULES]: (state, modules) => (state.modulesToExport = modules),
  [RESET_EXPORT_MODULES]: state => (state.modulesToExport = []),
}

const getters = {
  currentRiscos: state => state.currentRiscos,
  allRiscos: state => state.allRiscos,
  codigosRiscosEsocial: state => state.codigosRiscosEsocial,
  getEditRiscoIndex: state => state.editRiscoIndex,
  selectedRiscos: state => state.selectedRiscos,
  getModulesToExport: state => state.modulesToExport,
}

const actions = {
  setSelectedRiscos({ commit }, riscos) {
    commit(SET_SELECTED_RISCOS, riscos)
  },
  async searchByIdModule({ commit }, { currentModule, idModule }) {
    let response = null
    if (idModule && currentModule === 'funcao') response = await funcaoService.getRiscos(idModule)
    if (idModule && currentModule === 'setor') response = await setorService.getRiscos(idModule)
    commit(SET_CURRENT_RISCOS, response?.data || [])
  },
  async searchAll({ commit }) {
    const response = await riscoService.search(true)
    commit(SET_ALL_RISCOS, response.data)
  },
  async searchCodigosEsocial({ commit }) {
    const response = await riscoService.searchCodigosEsocial()
    commit(SET_CODIGOS_ESOCIAL, response.data)
  },
  setRisco: ({ commit, getters }, risco) => {
    const formattedRisco = {
      value: risco.novo_agente_nocivo.value,
      label: risco.novo_agente_nocivo.label,
      data: { ...risco },
    }

    const index = getters.getEditRiscoIndex

    if (index !== null) {
      commit(EDIT_RISCO, { risco: formattedRisco, index })
    } else {
      commit(ADD_RISCO, formattedRisco)
    }
  },
  resetRiscos: ({ commit }) => {
    commit(RESET_STATE)
  },
  resetSelected: ({ commit }) => {
    commit(RESET_SELECTED)
  },
  setModulesToExport({ commit }, modules) {
    commit(SET_EXPORT_MODULES, modules)
  },
  resetModulesToExport: ({ commit }) => {
    commit(RESET_EXPORT_MODULES)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
