import moment from 'moment'
import { conformToMask } from 'vue-text-mask'
import { isNil } from 'lodash'
import maskHelpers from './maskHelpers'

export const extractOnlyNumbers = value => (isNil(value) ? '' : value.trim().replace(/[^0-9]/g, ''))
export const extractOnlyNumbersAndLetters = value => (isNil(value) ? '' : value.trim().replace(/[^0-9a-zA-Z]]/g, ''))
export const formatPreco = value => {
  const options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }
  const formatNumber = new Intl.NumberFormat('pt-BR', options)
  return formatNumber.format(value)
}
export const normalizeText = text => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
export const capitalizeText = str => str.charAt(0).toUpperCase() + str.slice(1)
export const formatDate = (value, patternFrom = 'DD/MM/YYYY', patternTo = 'YYYY-MM-DD') => {
  if (isNil(value)) {
    return ''
  }

  let dateFormatted = moment(value, patternFrom).utc().format(patternTo)

  if (!moment(dateFormatted, patternTo).isValid()) {
    dateFormatted = ''
  }

  return dateFormatted
}
export const formatToHTML = text => text.replaceAll('\n', '<br />')

const formatter = {
  normalizeText,
  capitalizeText,
  extractOnlyNumbers,
  extractOnlyNumbersAndLetters,
  formatDate,
  defaultFormat: (value, mask) => (!isNil(value) ? conformToMask(value, mask, { guide: false }).conformedValue : null),
  formatCep: value => formatter.defaultFormat(value, maskHelpers.cepMask),
  formatCnpj: value => formatter.defaultFormat(value, maskHelpers.cnpjMask),
  formatCnae: value => formatter.defaultFormat(value, maskHelpers.cnaeMask),
  formatCaepf: value => formatter.defaultFormat(value, maskHelpers.caepfMask),
  formatTelefone: value => formatter.defaultFormat(value, maskHelpers.changePhoneMask(value)),
  formatTelefone10: value => formatter.defaultFormat(value, maskHelpers.phoneMask10),
  formatTelefone11: value => formatter.defaultFormat(value, maskHelpers.phoneMask11),
  formatCPF: value => formatter.defaultFormat(value, maskHelpers.cpfMask),
  formatRG: value => formatter.defaultFormat(value, maskHelpers.rgMask),
  formatPreco,
}

export default formatter
