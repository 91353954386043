const SNACKBAR_TIMEOUT = 150

export const SET_LOADING = 'SET_LOADING'
export const HIDE_ALL_MESSAGES = 'HIDE_ALL_MESSAGES'
export const SET_MESSAGE_ON_SNACKBAR = 'SET_MESSAGE_ON_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'

const state = {
  loading: false,
  snackbar: false,
  message: '',
  color: '',
}

const mutations = {
  [SET_LOADING]: (state, loading) => {
    state.loading = loading
  },
  [HIDE_ALL_MESSAGES]: state => {
    state.snackbar = false
  },
  [SET_MESSAGE_ON_SNACKBAR]: (state, { message, color }) => {
    state.snackbar = true
    state.message = message
    state.color = color
  },
  [HIDE_SNACKBAR]: state => {
    state.snackbar = false
  },
}

const actions = {
  showLoading({ commit }) {
    commit(SET_LOADING, true)
  },
  hideLoading({ commit }) {
    commit(SET_LOADING, false)
  },
  hideAllMessages({ commit }) {
    commit(HIDE_ALL_MESSAGES)
  },
  showErrorOnSnackbar({ commit }, errorMessage) {
    commit(HIDE_SNACKBAR)
    setTimeout(() => {
      if (typeof errorMessage === 'string') {
        commit(SET_MESSAGE_ON_SNACKBAR, { message: errorMessage, color: 'error' })
      } else {
        commit(SET_MESSAGE_ON_SNACKBAR, { message: JSON.stringify(errorMessage), color: 'error' })
      }
    }, SNACKBAR_TIMEOUT)
  },
  showSuccessOnSnackbar({ commit }, successMessage) {
    commit(HIDE_SNACKBAR)
    setTimeout(() => commit(SET_MESSAGE_ON_SNACKBAR, { message: successMessage, color: 'success' }), SNACKBAR_TIMEOUT)
  },
  showInfoOnSnackbar({ commit }, infoMessage) {
    commit(HIDE_ALL_MESSAGES)
    setTimeout(() => commit(SET_MESSAGE_ON_SNACKBAR, { message: infoMessage, color: 'info' }), SNACKBAR_TIMEOUT)
  },
  showWarningOnSnackbar({ commit }, warningMessage) {
    commit(HIDE_ALL_MESSAGES)
    setTimeout(() => commit(SET_MESSAGE_ON_SNACKBAR, { message: warningMessage, color: 'warning' }), SNACKBAR_TIMEOUT)
  },
  hideSnackbar({ commit }) {
    commit(HIDE_SNACKBAR)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
