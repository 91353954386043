import { cloneDeep, isNil, isEmpty, has } from 'lodash'
import empresaService from '@/services/empresa'
import { STATUS_EMPRESA } from '@/strings'
import { extractOnlyNumbers } from '@/core/utils/formatter'
import { compareTextLettersOnly } from '@/core/utils/comparatorUtils'

export const SET_EMPRESA = 'SET_EMPRESA'
export const SET_EMPRESA_FROM_FORM = 'SET_EMPRESA_FROM_FORM'
export const RESET_EMPRESA = 'RESET_EMPRESA'
export const SET_EMPRESA_BY_DATA = 'SET_EMPRESA_BY_DATA'
export const SET_CNPJ = 'SET_CNPJ'
export const SET_EMPRESAS = 'SET_EMPRESAS'
export const SET_FILTRO_STATUS = 'SET_FILTRO_STATUS'
export const SET_FILTRO_EMPRESA = 'SET_FILTRO_EMPRESA'
export const SET_STATUS_EMPRESA = 'SET_STATUS_EMPRESA'
export const SET_EMPRESA_BY_ID = 'SET_EMPRESA_BY_ID'
export const SET_EMPRESA_REGISTERED = 'SET_EMPRESA_REGISTERED'
export const SET_MATRIZ_ID_IN_UNIDADE = 'SET_MATRIZ_ID_IN_UNIDADE'
export const SET_ID_IN_EMPRESA = 'SET_ID_IN_EMPRESA'
export const SET_TIPO_MATRIZ = 'SET_TIPO_MATRIZ'
export const SET_EMPRESA_PLANO_COUNTER = 'SET_EMPRESA_PLANO_COUNTER'
export const SET_UNIDADE_PLANO_COUNTER = 'SET_UNIDADE_PLANO_COUNTER'
export const SET_QTD_EMPRESAS = 'SET_QTD_EMPRESAS'
export const SET_CNAES = 'SET_CNAES'
export const SET_SSO = 'SET_SSO'
export const SET_CPF = 'SET_CPF'
export const SET_TIPO_EMPRESA = 'SET_TIPO_EMPRESA'
export const SET_PLANO = 'SET_PLANO'
export const SET_CAEPF = 'SET_CAEPF'

const defaultEmpresa = {
  razaoSocial: '',
  cnpj: '',
  id: undefined,
  cnae: '',
  endereco: '',
  numero: '',
  bairro: '',
  cidade: undefined,
  uf: '',
  cep: '',
  status: null,
  tipo: null,
  idMatriz: null,
  grauRisco: null,
  idOrganizacao: null,
  permitirAgendamento: false,
  enable_agendamento_express: false,
  matriz: {},
  plano: {},
}

const state = {
  empresa: cloneDeep(defaultEmpresa),
  empresaRegistered: {
    id: null,
    razaoSocial: null,
  },
  busca: {
    empresas: {
      qtd: 0,
      list: [],
      filtro: {
        status: STATUS_EMPRESA.TODAS,
        razaoSocial: '',
      },
    },
  },
  cnaes: [],
  sso: {
    pgrs: [],
    pcmsos: [],
    ltcats: [],
  },
}

const mutations = {
  [SET_STATUS_EMPRESA]: (state, status) => {
    state.empresa.status = status
  },
  [SET_PLANO]: (state, plano) => {
    state.empresa.plano = cloneDeep(plano)
  },
  [SET_EMPRESA]: (state, empresa) => {
    state.empresa = {
      cnpj: state.empresa.cnpj,
      cpf: state.empresa.cpf,
      caepf: state.empresa.caepf,
      ...empresa,
      id: empresa.id_empresa || empresa.id,
    }
  },
  [SET_TIPO_MATRIZ]: state => {
    state.empresa.tipo = 1
  },
  [SET_TIPO_EMPRESA]: (state, tipo_empresa) => {
    state.empresa.tipo_empresa = tipo_empresa
  },
  [SET_MATRIZ_ID_IN_UNIDADE]: (state, idMatriz) => {
    state.empresa.idMatriz = idMatriz
    state.empresa.tipo = 2
  },
  [SET_EMPRESA_REGISTERED]: (state, payload) => {
    state.empresaRegistered.id = payload.id_empresa
    state.empresaRegistered.razaoSocial = payload.razao_social
  },
  [SET_EMPRESA_FROM_FORM]: (state, empresa) => {
    state.empresa.cnae = empresa.cnae
    state.empresa.bairro = empresa.bairro
    state.empresa.cep = empresa.cep
    state.empresa.cidade = empresa.cidade
    state.empresa.numero = empresa.numero
    state.empresa.endereco = empresa.endereco
    state.empresa.id = empresa.id
    state.empresa.razaoSocial = empresa.razaoSocial
    state.empresa.uf = empresa.uf
    state.empresa.grauRisco = empresa.grauRisco
    state.empresa.idOrganizacao = empresa.idOrganizacao
    state.empresa.permitirAgendamento = empresa.permitirAgendamento
    state.empresa.matriz = empresa.matriz
  },
  [SET_CNPJ]: (state, cnpj) => {
    state.empresa.cnpj = cnpj
  },
  [SET_CPF]: (state, cpf) => {
    state.empresa.cpf = cpf
  },
  [SET_CAEPF]: (state, caepf) => {
    state.empresa.caepf = caepf
  },
  [RESET_EMPRESA]: state => {
    state.empresa = cloneDeep(defaultEmpresa)
  },
  [SET_EMPRESA_BY_DATA]: (state, payload) => {
    state.empresa.cnae = payload.cnae_principal_codigo
    state.empresa.bairro = payload.endereco_bairro
    state.empresa.cep = payload.endereco_cep
    state.empresa.cidade = { id_cidade: payload.endereco_id_cidade, nome: payload.endereco_nome_cidade }
    state.empresa.numero = payload.endereco_numero
    state.empresa.endereco = payload.endereco_rua
    state.empresa.id = payload.id
    state.empresa.razaoSocial = payload.razao_social
    state.empresa.status = payload.status_empresa
    state.empresa.uf = payload.endereco_uf
    state.empresa.grauRisco = payload.grau_risco
    state.empresa.idOrganizacao = payload.id_organizacao
    state.empresa.permitirAgendamento = payload.permitir_agendamento
    state.empresa.matriz = payload.matriz
  },
  [SET_EMPRESA_BY_ID]: (state, payload) => {
    state.empresa.cnpj = payload.cnpj
    state.empresa.cpf = payload.cpf
    state.empresa.caepf = payload.caepf
    state.empresa.cnae = payload.cnae_principal_codigo
    state.empresa.bairro = payload.endereco_bairro
    state.empresa.cep = payload.endereco_cep
    state.empresa.cidade = { id_cidade: payload.endereco_id_cidade }
    state.empresa.numero = payload.endereco_numero
    state.empresa.endereco = payload.endereco_rua
    state.empresa.id = payload.id_empresa
    state.empresa.dt_criacao = payload.dt_criacao
    state.empresa.email = payload.usuario ? payload.usuario.email : ''
    state.empresa.email_principal = payload.usuarioEmpresa[0] ? payload.usuarioEmpresa[0].usuario.email : ''
    state.empresa.email_comprador = payload.plano ? payload.plano.email_faturamento : ''
    state.empresa.razaoSocial = payload.razao_social
    state.empresa.status = payload.status
    state.empresa.uf = payload.cidade ? payload.cidade.uf : ''
    state.empresa.grauRisco = payload.grau_risco
    state.empresa.idOrganizacao = payload.id_organizacao
    state.empresa.permitirAgendamento = payload.permitir_agendamento
    state.empresa.matriz = payload.matriz
    state.empresa.responsavelSeguranca = payload.responsavelSeguranca || payload.organizacao?.responsavelSeguranca
    state.empresa.enable_agendamento_express = payload.enable_agendamento_express
    state.empresa.hide_matriz = payload.hide_matriz
    state.empresa.show_matriz_sso = payload.show_matriz_sso
    state.empresa.idMatriz = payload.id_matriz
  },
  [SET_EMPRESAS]: (state, payload) => {
    state.busca.empresas.list = payload.empresas
    state.busca.empresas.qtd = payload.empresas.length
  },
  [SET_CNAES]: (state, payload) => {
    state.cnaes = payload
  },
  [SET_SSO]: (state, payload) => {
    state.sso = payload.sso
  },
  [SET_FILTRO_STATUS]: (state, payload) => {
    state.busca.empresas.filtro.status = payload
  },
  [SET_FILTRO_EMPRESA](state, filtro) {
    for (const key in filtro) {
      if (has(filtro, key)) {
        state.busca.empresas.filtro[key] = filtro[key]
      }
    }
  },
  [SET_ID_IN_EMPRESA]: state => {
    state.empresa.id = 0
  },
  [SET_EMPRESA_PLANO_COUNTER]: (state, { idEmpresa, counter }) => {
    const newEmpresas = state.busca.empresas.list.map(empresa => {
      if (empresa.id_empresa === idEmpresa) {
        empresa.plano_count = counter
      }
      return empresa
    })

    state.busca.empresas.list = newEmpresas
  },
  [SET_UNIDADE_PLANO_COUNTER]: (state, { idMatriz, idUnidade, counter }) => {
    const newEmpresas = state.busca.empresas.list.map(empresa => {
      if (empresa.id_empresa === idMatriz) {
        empresa.unidades.map(unidade => {
          if (unidade.id_empresa === idUnidade) {
            unidade.plano_count = counter
          }
          return unidade
        })
      }
      return empresa
    })

    state.busca.empresas.list = newEmpresas
  },
  [SET_QTD_EMPRESAS]: (state, qtdEmpresas) => (state.busca.empresas.qtd = qtdEmpresas),
}

const getters = {
  getActualId: state => {
    if (!isNil(state.empresa.id)) {
      return state.empresa.id
    }
    return state.empresaRegistered.id
  },
  getActualRazaoSocial: state => {
    if (!isNil(state.empresa.id)) {
      return state.empresa.razaoSocial
    }
    return state.empresaRegistered.razaoSocial
  },
  empresa: state => state.empresa,
  empresas: state => state.busca.empresas.list,
  existsEmpresa: state => state.busca.empresas.list.some(empresa => empresa.id_empresa === state.empresa.id),
  empresaFound: state => !isNil(state.empresa.id),
  empresasFiltered: state => {
    let empresasFiltered = state.busca.empresas.list

    if (state.busca.empresas.filtro.status !== STATUS_EMPRESA.TODAS) {
      empresasFiltered = empresasFiltered.filter(e => e.status === state.busca.empresas.filtro.status)
    }

    if (state.busca.empresas.filtro.razaoSocial.length > 0) {
      empresasFiltered = empresasFiltered.filter(
        e => compareTextLettersOnly(e.razao_social, state.busca.empresas.filtro.razaoSocial)
        // eslint-disable-next-line function-paren-newline
      )
    }
    return empresasFiltered
  },
  getFiltroEmpresa: state => state.busca.empresas.filtro,
  hasEmpresas: state => !isEmpty(state.busca.empresas.list),
  qtdEmpresas: state => state.busca.empresas.qtd,
  empresaRegistered: state => state.empresaRegistered,
  unicaEmpresa: state => {
    if (state.busca.empresas.list.length === 1) {
      return state.busca.empresas.list[0]
    }
    return false
  },
  isUnidade: state => !!state.empresa.idMatriz,
  isMatriz: state => state.empresa.tipo === 1,
  matrizSemUnidades: state => isEmpty(state.empresa.unidades),
  cnaes: state => state.cnaes,
  sso: state => state.sso,
}

const actions = {
  async save({ commit, getters }, empresa) {
    if (!getters.existsEmpresa && (isNil(empresa.id) || empresa.id === 0)) {
      if (!empresa.idMatriz) {
        empresa.tipo = 1
      }
      const params = {
        cnpj: getters.empresa.cnpj,
        cpf: getters.empresa.cpf,
        ...empresa,
        status: empresa.status || STATUS_EMPRESA.ATIVA,
      }
      const response = await empresaService.insert(params)
      commit(SET_EMPRESA_REGISTERED, response.data)
    } else {
      const params = {
        cnpj: getters.empresa.cnpj,
        cpf: getters.empresa.cpf,
        ...empresa,
        status: empresa.status,
      }
      await empresaService.update(params)
    }
    commit(RESET_EMPRESA)
  },
  async cancel({ commit }) {
    commit(RESET_EMPRESA)
  },
  async createEmpresaDados({ commit, dispatch }, cnpj) {
    commit(SET_STATUS_EMPRESA, STATUS_EMPRESA.ATIVA)
    await dispatch('populateEmpresa', cnpj)
  },
  async populateEmpresa({ commit, state }, params) {
    const data = extractOnlyNumbers(params.data)
    const { tipo_empresa, caepf } = params
    commit(SET_TIPO_EMPRESA, tipo_empresa)
    if (isNil(state.empresa.id)) {
      let response = {}
      try {
        if (params.tipo_empresa === 'cnpj') {
          commit(SET_CNPJ, data)
          commit(SET_CPF, '')
          commit(SET_CAEPF, '')
          response = await empresaService.findByCnpj(data)
        } else {
          commit(SET_CPF, data)
          commit(SET_CNPJ, '')
          commit(SET_CAEPF, caepf)
          response = await empresaService.findByCpf(data)
        }
        commit(SET_EMPRESA_BY_DATA, response.data)
        return { isRegistered: !isNil(state.empresa.id) && state.empresa.id !== 0 }
      } catch (e) {
        if (e.response.status !== 404 && e.response.status !== 500) {
          throw e
        }
        return { isRegistered: false }
      }
    }
    return null
  },
  async search({ commit }) {
    const response = await empresaService.search()
    commit(SET_EMPRESAS, response.data)
  },
  async loadEmpresaById({ commit }, id) {
    const response = await empresaService.findById(id)
    commit(SET_EMPRESA_BY_ID, response.data)
  },
  async searchCnaes({ commit }) {
    const response = await empresaService.searchCnaes()
    commit(SET_CNAES, response.data)
  },
  async getSso({ commit }, idEmpresa) {
    const response = await empresaService.getSso(idEmpresa)
    commit(SET_SSO, response.data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
