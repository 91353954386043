import Axios from 'axios'
import { isNil } from 'lodash'
import Cookies from 'js-cookie'
import formatter from '@/core/utils/formatter'

export default {
  login: async (email, senha) => Axios.post('/medico/login', { email, senha }),
  registrar: async data => Axios.post('/medico/registrar', data),
  // prettier-ignore
  redefinirSenha: (senhaAntiga, senhaNova, primeiroAcesso = false) => Axios.put('/medico/redefinir-senha', { senhaAntiga, senhaNova, primeiroAcesso }),
  setToken: token => Cookies.set('medico_token', token),
  getToken: () => Cookies.get('medico_token'),
  isAuthenticated: () => !isNil(Cookies.get('medico_token')),
  removeToken: () => Cookies.remove('medico_token'),
  buscaAgendamentos: () => Axios.get('/medico/agendamentos'),
  finalizarAso: ({ id_aso, dt_finalizacao, apto, observacoes, status = 3 }) => {
    return Axios.post(`/medico/asos/${id_aso}/finalizar`, {
      dt_finalizacao: formatter.formatDate(dt_finalizacao),
      apto,
      observacoes,
      status,
    })
  },
  salvarAssinatura: assinatura => {
    const formData = new FormData()
    formData.append('file', assinatura)
    return Axios.post('/medico/assinatura', formData)
  },
  getAssinatura: () => Axios.get('/medico/assinatura'),
  gerarHashCadastro: () => Axios.post('/admin/medicos/hash'),
  buscaMedicos: (onlyAtivos = false, tipo = null) => Axios.get('/admin/medicos', { params: { onlyAtivos, tipo } }),
  liberarDatas: (id, dates) => Axios.post(`/admin/medicos/${id}/datas-liberadas`, { id, dates }),
  getDatasLiberadas: id => Axios.get(`/admin/medicos/${id}/datas-liberadas`),
  buscaAgendamentosPorId: id => Axios.get(`/admin/medicos/${id}/asos`),
  syncAsos: (idAso, asos) => Axios.post(`/admin/medicos/${idAso}/asos`, { asos }),
  saveHorarios: horarios => Axios.post('/medico/horarios', horarios),
  responderFichaClinica: (id_aso, respostas) => Axios.post('/medico/asos/responder-ficha', { id_aso, respostas }),
  respostasMedicoFichaClinica: idAso => Axios.get(`/medico/asos/respostas-ficha/${idAso}`),
  getHorarios: () => Axios.get('/medico/horarios'),
  deleteHorario: ({ idHorario }) => Axios.delete(`/medico/horarios/${idHorario}`),
  openFichaClinica: idAso => Axios.get(`/medico/asos/${idAso}/ficha-clinica/download`),
  openAso: idAso => Axios.get(`/medico/asos/${idAso}/arquivo-retorno/download`),
  edit: (medico, idMedico) => Axios.put(`/admin/medicos/${idMedico}`, medico),
}
