import { toLower } from 'lodash'
import { normalizeText } from '@/core/utils/formatter'

export const compareTextLettersOnly = (value, queryText) =>
  toLower(normalizeText(value)).includes(toLower(normalizeText(queryText)))
export const equalsTextLettersOnly = (value, queryText) =>
  toLower(normalizeText(value)) === toLower(normalizeText(queryText))
export const compareRaizCnpj = (empresa1, empresa2) => {
  const raizCnpjEmpresa1 = empresa1 ? empresa1.slice(0, 8) : ''
  const raizCnpjEmpresa2 = empresa2 ? empresa2.slice(0, 8) : ''

  return raizCnpjEmpresa1 === raizCnpjEmpresa2
}

export default compareTextLettersOnly
