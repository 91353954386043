<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <router-view />
    <page-footer v-if="isAuthenticated()" />
  </v-app>
</template>

<script>
import PageFooter from '@/core/components/PageFooter'
import autenticacaoService from '@/services/autenticacao'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    env: process.env,
    envInfoActive: true,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    ...mapGetters('autenticacao', ['id_usuario', 'email', 'username']),
  },
  components: {
    PageFooter,
  },
  methods: {
    isAuthenticated() {
      return autenticacaoService.isAuthenticated()
    },
    setDatadogUser() {
      const user = this.$getDatadogUser()
      if (this.isAuthenticated && !user) {
        this.$setDatadogUser(this.id_usuario, this.email, this.username)
      }
    },
  },
  mounted() {
    this.$initDatadog()
    this.setDatadogUser()
  },
}
</script>

<style>
:root {
  --primary: #006bf2;
  --secondary: #f6f7fa;
  --background: #f0f3f5;
  --grey: #535d67;
  --greylight: '#707070';
  --greysoft: '#d7d7d7';
}

.page-title {
  font-size: 2em;
  font-weight: normal;
  color: var(--greylight);
}

/*
 * Tabs
 *
*/

.v-slide-group__content {
  border-bottom: solid 1px #d7d7d7;
}

/*
 * Tabs and Tooltip effects
 *
 */

.v-ripple__container {
  background: transparent;
}

.v-tab:before {
  background: transparent;
}

/*
 * Buttons
 *
*/

button:focus {
  outline-style: none !important;
}

/*
 * Pagination
 *
*/
.v-pagination__item,
.v-pagination__item--active,
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
