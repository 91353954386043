import Axios from 'axios'

export default {
  search: () => Axios.get('/exames'),
  edit: async exame => {
    const params = {
      id_exame: exame.id_exame,
      nome: exame.nome,
      validade: exame.validade,
      preco: exame.preco.replace(',', '.'),
      codigo_esocial: exame.codigo_esocial,
    }
    return Axios.put('/exames', params)
  },
  create: async exame => {
    const params = {
      nome: exame.nome,
      validade: exame.validade,
      preco: exame.preco.replace(',', '.'),
      codigo_esocial: exame.codigo_esocial,
    }
    return Axios.post('/exames', params)
  },
  searchCodigosEsocial: () => Axios.get('/exames/codigos-esocial'),
}
