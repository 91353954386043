import { isEmpty, cloneDeep, has } from 'lodash'
import funcaoService from '@/services/funcao'
import { compareTextLettersOnly } from '@/core/utils/comparatorUtils'
import { COMMON_STATUS } from '@/strings'

export const SET_FUNCOES = 'SET_FUNCOES'
export const SET_FUNCAO = 'SET_FUNCAO'
export const SET_FILTRO = 'SET_FILTRO'
export const RESET_FUNCAO = 'RESET_FUNCAO'
export const SET_EXAMES_FUNCAO = 'SET_EXAMES_FUNCAO'

const defaultFuncao = {
  name: '',
  description: '',
  cbo: '',
  riscos: [],
  exames: [],
  periculosidade: '',
  insalubridade: '',
  nocividade: '',
}

const state = {
  funcao: cloneDeep(defaultFuncao),
  busca: {
    funcoes: [],
    filtro: {
      name: '',
    },
  },
}

const mutations = {
  [RESET_FUNCAO]: state => {
    state.funcao = cloneDeep(defaultFuncao)
  },
  [SET_FUNCOES]: (state, funcoes) => {
    state.busca.funcoes = funcoes
  },
  [SET_EXAMES_FUNCAO]: (state, exames) => {
    state.funcao.exames = exames
  },
  [SET_FUNCAO]: (state, payload) => {
    state.funcao.name = payload.nome
    state.funcao.description = payload.descricao
    state.funcao.id = payload.id_funcao
    state.funcao.cbo = payload.id_cbo
    state.funcao.riscos = payload.riscos.map(r => ({ value: r.id_risco, label: r.nome, data: r.data }))
    state.funcao.exames = payload.exames.map(r => ({
      value: r.id_exame,
      label: r.nome,
      validade: r.validade,
      tipos: r.tipos,
    }))
    // Exames should be disabled, Ex: only avaliação clinica
    state.funcao.exames.filter(e => e.value === 1).forEach(e => (e.disabled = true))
    state.funcao.isActive = payload.status
    state.funcao.periculosidade = payload.periculosidade
    state.funcao.insalubridade = payload.insalubridade
    state.funcao.nocividade = payload.nocividade
  },
  [SET_FILTRO](state, filtro) {
    for (const key in filtro) {
      if (has(filtro, key)) {
        state.busca.filtro[key] = filtro[key]
      }
    }
  },
}

const getters = {
  funcoes: state => state.busca.funcoes,
  itemsFiltered: state => {
    let funcoesFiltered = state.busca.funcoes

    const funcaoToSearch = state.busca.filtro.name

    if (!isEmpty(funcaoToSearch)) {
      funcoesFiltered = funcoesFiltered.filter(f => compareTextLettersOnly(f.name, funcaoToSearch))
    }

    return funcoesFiltered
  },
  getFiltro: state => state.busca.filtro,
  modelForEdit: state => state.funcao,
  isEmptyList: state => isEmpty(state.busca.funcoes),
}

const actions = {
  async search({ commit }, { idEmpresa, id }) {
    const response = await funcaoService.searchFuncoes(idEmpresa, id)
    commit(SET_FUNCOES, response.data)
  },
  async save({ commit }, { idEmpresa, model }) {
    model.status = COMMON_STATUS.ATIVA

    await funcaoService.save(model, idEmpresa)
    commit(RESET_FUNCAO)
  },
  async loadById({ commit }, { idEmpresa, id }) {
    const response = await funcaoService.findById(idEmpresa, id)
    commit(SET_FUNCAO, response.data)
  },
  async updateStatus({}, { id, status }) {
    return funcaoService.updateStatus(id, status)
  },
  async exportRiscos({}, { riscos, idsModule }) {
    await funcaoService.exportRiscos({ riscos, idsFuncoes: idsModule })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
