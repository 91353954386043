export const STATUS_EMPRESA = {
  TODAS: -1,
  ATIVA: 1,
  INATIVA: 2,
  BLOQUEADA: 3
}

export const COMMON_STATUS = {
  ATIVA: 1,
  INATIVA: 2
}

export default {
  estados: [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
  ],
  sexo: [
    { value: 'M', text: 'Masculino' },
    { value: 'F', text: 'Feminino' }
  ],
  STATUS_EMPRESA
}
